import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import MainLayout from '../components/shared/layout/MainLayout';
import AccountDetails from '../container/AccountDetails';
import Home from '../container/Home';
import PaymentSuccess from '../components/AccountDetails/BillingPlansModal/StripeSuccess';
import PaymentCancel from '../components/AccountDetails/BillingPlansModal/StripeCancel';
import ProtectedStripeRoute from './stripeProtectedRoutes';
import SessionHistory from '../container/SessionHistory';
import FAQ from '../container/FAQ';
import Support from '../container/Support';
import VerifyEmail from '../container/SignUp/EmailVerification';
import Trial from '../container/Trial';
import ProfileProtectedRoute, { TrialProtectedRoute } from './trialProtectedRoute';
import UserProfile from '../container/UserProfile';

const centerClass = 'items-center justify-center ';
const Routing: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/home"
        element={
          <TrialProtectedRoute>
            <MainLayout>
              <Home />
            </MainLayout>
          </TrialProtectedRoute>
        }
      />
      <Route
        path="/stripe-success"
        element={
          <MainLayout showBackButton isSideBar={false} className={centerClass}>
            <ProtectedStripeRoute component={PaymentSuccess} />
          </MainLayout>
        }
      />
      <Route
        path="/stripe-cancel"
        element={
          <MainLayout showBackButton isSideBar={false} className={centerClass}>
            <ProtectedStripeRoute component={PaymentCancel} />
          </MainLayout>
        }
      />
      <Route
        path="/account-details"
        element={
          <TrialProtectedRoute>
            <MainLayout showBackButton>
              <AccountDetails />
            </MainLayout>
          </TrialProtectedRoute>
        }
      />
      <Route
        path="/session-history"
        element={
          <TrialProtectedRoute>
            <MainLayout showBackButton>
              <SessionHistory />
            </MainLayout>
          </TrialProtectedRoute>
        }
      />
      <Route
        path="/faq"
        element={
          <TrialProtectedRoute>
            <MainLayout showBackButton>
              <FAQ />
            </MainLayout>
          </TrialProtectedRoute>
        }
      />
      <Route
        path="/support"
        element={
          <TrialProtectedRoute>
            <MainLayout showBackButton>
              <Support />
            </MainLayout>
          </TrialProtectedRoute>
        }
      />
      <Route
        path="/trial"
        element={
          <MainLayout isSideBar={false}>
            <Trial />
          </MainLayout>
        }
      />
      <Route
        path="/profile"
        element={
          <ProfileProtectedRoute>
            <MainLayout isSideBar={false}>
              <UserProfile isProfilePage={true} />
            </MainLayout>
          </ProfileProtectedRoute>
        }
      />
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/verification" element={<VerifyEmail />} />
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );
};

export default Routing;
