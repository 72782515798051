import React, { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

import { Button } from '../../components/shared/ui/Button';
import { CheckoutSessionResponse } from '../../common/types/stripeSessions';
import useGenericMutation from '../../CustomHooks/useMutation';
import {
  axiosStripeInstance,
  useAxiosStripeInterceptors,
} from '../../api/apiInstances';
import { setStripeData } from '../../redux/feature/StripeSlice';
import { useDispatch } from 'react-redux';
import { setIsCheckingOut } from '../../redux/feature/CheckOutSlice';
import TagManager from 'react-gtm-module';
import useAlert from '../../CustomHooks/useAlert';
import Alert from '../../components/shared/ui/Alert';
import BillingPlanModal from '../../components/AccountDetails/BillingPlansModal';

const Trial = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  useAxiosStripeInterceptors();
  const { alert, setAlert, closeAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);

  const { mutateAsync: createCheckoutSession, isLoading: checkoutLoading } =
    useGenericMutation<CheckoutSessionResponse>(
      '/subscription/create-checkout-session',
      'POST',
      axiosStripeInstance
    );

  const { mutateAsync: updateUserProfile } = useGenericMutation<any>(
    '/users/profile',
    'PUT',
    axiosStripeInstance
  );

  useLayoutEffect(() => {
    const fetchUserProfile = async () => {
      if (user?.id) {
        try {
          const response = await axiosStripeInstance.get<any>(
            `/users/${user.id}`
          );
          if (response.data.hasVisitedTrialPage) {
            navigate('/home');
          } else {
            setIsLoading(false);
          }
        } catch (error: any) {
          const message = error.response?.data?.message;
          setAlert({
            isSuccess: false,
            message: message || 'Error fetching user profile',
          });
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchUserProfile();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    fetchUserProfile();

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [navigate, setAlert, user]);

  const dispatch = useDispatch();

  const params = new URLSearchParams();
  params.set('fromStripe', 'true');
  params.set('trial', 'true');

  const encodedParams = btoa(params.toString());
  const successPath = `/stripe-success?params=${encodedParams}`;
  const cancelPath = `/stripe-cancel?params=${encodedParams}`;

  const [isSkipLoading, setIsSkipLoading] = useState(false);
  const [plansModalOpened, setPlansModalOpened] = useState(false);
  const handleStartTrial = async () => {
    try {
      const { data: trialData } =
        await axiosStripeInstance.get<any>(`/subscription/trial`);

      const response = await createCheckoutSession({
        planId: trialData?.id ?? '',
        userId: user?.id ?? '',
        successUrl: `${window.location.origin}${successPath}`,
        cancelUrl: `${window.location.origin}${cancelPath}`,
        planType: trialData?.type ?? '',
      });

      TagManager.dataLayer({
        dataLayer: {
          event: 'checkout_start',
          checkout_session_id: response.id,
          plan_id: trialData?.id,
          listing_count: '1',
          price: trialData?.price,
        },
      });

      dispatch(
        setStripeData({
          stripeUrl: response.paymentUrl,
          selectedPlan: trialData?.id ?? '',
        })
      );
      dispatch(setIsCheckingOut(true));

      window.open(response.paymentUrl);
    } catch (error: any) {
      const message = error.response?.data?.message;
      setAlert({
        isSuccess: false,
        message: message || 'Error starting trial',
      });
      console.error('Error starting trial:', error);
    }
  };

  const handleSkipTrial = async () => {
    setIsSkipLoading(true);
    try {
      TagManager.dataLayer({
        dataLayer: {
          event: 'trial_skipped',
        },
      });
      await updateUserProfile({ hasVisitedTrialPage: true });
      navigate('/home');
    } catch (error: any) {
      const message = error.response?.data?.message;
      setAlert({
        isSuccess: false,
        message: message || 'Error updating user profile',
      });
      console.error('Error updating user profile:', error);
    } finally {
      setIsSkipLoading(false);
    }
  };

  return (
    <>
      {alert && (
        <Alert
          isSuccess={alert.isSuccess}
          message={alert.message}
          onClose={closeAlert}
        />
      )}
      {isLoading ? (
        <div></div>
      ) : (
        <div className="content-container bg-[#F9F9F9] ">
          <div className="bg-white px-4 py-11 md:px-8 rounded-lg shadow-md md:min-w-[600px] min-w-full  border border-[#C9CBCD] flex flex-col justify-center ">
            <div className="flex justify-center mb-2">
              <img
                src={'etSEOLogo.png'}
                alt="Confirmation Icon"
                className="w-16 h-16"
              />
            </div>
            <h1 className="text-3xl font-bold mt-10 mb-1 tracking-wider text-center">
              Start a Trial
              <br className="md:hidden" /> for only $1
            </h1>
            <h2 className="text-[#3F3F3F] text-base  mb-2 mt-2 text-center">
              Experience access to more features for a{' '}
              <br className="md:hidden" /> limited time.{' '}
              <br className="hidden md:block" />
              You'll be able to have 1 listing <br className="md:hidden" />{' '}
              generation
            </h2>
            <div className="flex justify-center gap-4 mt-9 w-full">
              <Button
                label={'Purchase Bundle'}
                onClick={() => setPlansModalOpened(true)}
                loading={isSkipLoading}
                className="px-4 py-2 rounded-lg font-bold text-lg border border-etsyPrimary text-etsyPrimary w-full bg-white"
              />
              <Button
                label={'Start $1 Trial'}
                onClick={handleStartTrial}
                className="px-4 py-2 rounded-lg font-bold text-lg border border-etsyPrimary bg-etsyPrimary text-white w-full"
                loading={checkoutLoading}
              />
            </div>
              <BillingPlanModal
                isOpen={plansModalOpened}
                onClose={() => setPlansModalOpened(false)}
                isModal={true}
              />
          </div>
        </div>
      )}
    </>
  );
};

export default Trial;
